import Passions from "../Components/Passions";
import "../chat.css";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot,
  query,
  orderBy,
  limit,
  writeBatch,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebase } from "../Components/Firebase";
import { useSelector } from "react-redux";
import { format } from "date-fns"; // Import date-fns for formatting
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import axios from "axios";
import Download from "../Components/Download";
import ImageModal from "../Components/ImageModal";

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return format(date, "EEE dd - hh:mm a"); // Format as "Fri 24 - 02:30 AM"
};

const Chat = () => {
  const state = useSelector((state) => state?.user);
  const { id } = useParams();
  const db = getFirestore(firebase);

  const [previewImage, setPreviewImage] = useState(null);
  const [data, setData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // State for messages
  const [searchQuery, setSearchQuery] = useState("");
  const [chatUser, setChatUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blockedBy, setBlockedBy] = useState([]);
  const chatContainerRef = useRef(null);
  const [blockName, setBlockName] = useState(null);

  const [degree, setDegree] = useState("");
  const [showImageModal, setImageModal] = useState(false);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, loading, data]);

  const getLastMessage = async (chatRef) => {
    const chatSnapshot = await getDocs(
      query(chatRef, orderBy("createdAt", "desc"), limit(1))
    );
    if (!chatSnapshot.empty) {
      return chatSnapshot.docs[0].data();
    }
    return null;
  };

  useEffect(() => {
    if (state.user?.id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/get/login/user/${id}`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((res) => {
          setChatUser(res?.data?.user);
        })
        .catch((err) => {
          toast.error("There is something went wrong!");
        });
    }
  }, [id]);

  useEffect(() => {
    if (state.user?.id) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/get/block/user/${state.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((res) => {
          setBlockData(res?.data?.blockIds);
        })
        .catch((err) => {
          toast.error("There is something went wrong!");
        });
    }
  }, []);

  useEffect(() => {
    if (state.user?.id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/get/block/user/${id}`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((res) => {
          setBlockedBy(res?.data?.blockIds);
        })
        .catch((err) => {});
    }
  }, [id]);

  useEffect(() => {
    const fetchUsersWithChats = async () => {
      const unsubscribeFromUsers = onSnapshot(
        collection(db, "users"),
        async (userSnapshot) => {
          const allUsersWithChats = [];
          const chatPromises = [];

          // Loop through all users except the current user
          userSnapshot.docs.forEach((docSnap) => {
            const userId = docSnap.id;

            // Skip the current user
            if (userId === state.user?.id) return;

            // Create both forward and reverse chat IDs
            const chatId = `${state.user?.id}${userId}`;
            const reverseChatId = `${userId}${state.user?.id}`;

            // References for the messages
            const chatRef1 = collection(db, `chats/${chatId}/messages`);
            const chatRef2 = collection(db, `chats/${reverseChatId}/messages`);

            // Fetch the most recent message from both chats and store the promise
            chatPromises.push(
              Promise.all([
                getLastMessage(chatRef1),
                getLastMessage(chatRef2),
              ]).then(([lastMsgInChat1, lastMsgInChat2]) => {
                let lastMessage = null;

                // Determine the most recent message
                if (lastMsgInChat1 && lastMsgInChat2) {
                  lastMessage =
                    new Date(lastMsgInChat1.createdAt) >
                    new Date(lastMsgInChat2.createdAt)
                      ? lastMsgInChat1
                      : lastMsgInChat2;
                } else if (lastMsgInChat1) {
                  lastMessage = lastMsgInChat1;
                } else if (lastMsgInChat2) {
                  lastMessage = lastMsgInChat2;
                }

                // If there is a last message, add this user to the list
                if (lastMessage) {
                  allUsersWithChats.push({
                    id: userId,
                    lastMessage, // Store the last message with this user
                    ...docSnap.data(),
                  });
                }
              })
            );
          });

          // Wait for all messages to be fetched
          await Promise.all(chatPromises);

          // Sort users by the timestamp of the latest message
          const sortedUsers = allUsersWithChats.sort((a, b) => {
            return (
              new Date(b.lastMessage.createdAt) -
              new Date(a.lastMessage.createdAt)
            );
          });

          setData(sortedUsers);
          if (loading === true) {
            setLoading(false);
          }
        }
      );

      // Cleanup function to unsubscribe from the listener
      return () => {
        unsubscribeFromUsers();
      };
    };

    fetchUsersWithChats();
  }, [db, state?.user?.id, message, blockData]);

  useEffect(() => {
    const fetchMessages = () => {
      const chatId = state?.user?.id + id;
      const messagesCollection = collection(db, `chats/${chatId}/messages`);

      // Query to get messages in real-time and order them by createdAt
      const q = query(messagesCollection, orderBy("createdAt", "asc"));

      // Listening to changes in the collection in real-time
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(fetchedMessages);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    };

    fetchMessages();
  }, [db, id, state?.user?.id, blockData]);

  async function onSend() {
    if (previewImage == "" && message == "") {
      toast.error("Add some content to send message");
      return;
    }

    if (state?.login == true && state?.user?.feature_profile != 1) {
      toast.error("Feature your profile to send message");
      return;
    }
    if (chatUser !== null) {
      if (state?.login == true && chatUser?.feature_profile == 0) {
        toast.error(`${chatUser?.name} is no longer featured.`);
        return;
      }
    }

    const checkedBlock = blockData.find((e) => e == id);

    if (checkedBlock) {
      toast.error(
        `You blocked ${chatUser?.name}. Unblock ${chatUser?.name} to enable chats.`
      );
      return;
    }

    const chatId = state.user.id + id;
    const reverseChatId = id + state.user.id;

    const myMsg = {
      text: message,
      sendBy: state.user.id,
      sendTo: id,
      read: false,
      createdAt: Date.now(),
      user: {
        _id: state?.user?.id,
        image: state?.user?.image,
        name: state?.user?.name,
      },
    };

    // Check if there's an image to upload
    if (previewImage) {
      // Create a unique filename for the image
      const storage = getStorage();
      const storageRef = ref(storage, "chat-images/" + Date.now());
      const response = await fetch(previewImage);
      const blob = await response.blob();

      // Upload the image to Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, blob);
      await uploadTask;

      // Get the image URL after the upload is complete
      const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);

      // Add the image URL to the message object
      myMsg.image = imageUrl;
    }

    setMessage("");

    try {
      const messageRef = await addDoc(
        collection(db, `chats/${chatId}/messages`),
        myMsg
      );
      const messageId = messageRef.id;

      await addDoc(collection(db, `chats/${reverseChatId}/messages`), {
        ...myMsg,
        _id: messageId,
      });

      const userDocRef = doc(db, `users/${id}`);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        await updateDoc(userDocRef, {
          unReadMessages: arrayUnion(messageId),
        });
      } else {
        await setDoc(userDocRef, {
          unReadMessages: [messageId],
        });
      }

      // Optionally fetch messages again to update UI
      const updatedMessages = [...messages, { ...myMsg, _id: messageId }];
      setMessages(updatedMessages);
      setPreviewImage(null);
      removeImage();
    } catch (error) {
      toast.error("There is something went wrong!");
    }
  }

  // Filter users based on search query
  const filteredUsers = data.filter((user) =>
    user?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  const clearChat = async (otherUserId) => {
    const chatId = `${state.user?.id}${otherUserId}`;
    const reverseChatId = `${otherUserId}${state.user?.id}`;

    try {
      // Initialize a batch write
      const batch = writeBatch(db);
      const chatRefs = [
        collection(db, `chats/${chatId}/messages`),
        collection(db, `chats/${reverseChatId}/messages`),
      ];

      for (const chatRef of chatRefs) {
        const snapshot = await getDocs(chatRef);
        snapshot.forEach((doc) => {
          // Add each message to the batch for deletion
          batch.delete(doc.ref);
        });
      }

      // Commit the batch to delete all messages
      await batch.commit();
      toast.success("Chat cleared successfully!");
    } catch (error) {
      toast.error("Failed to clear chat. Try again.");
    }
  };

  useEffect(() => {
    if (state?.login == false) {
      setLoading(false);
      toast.warning("You have to sign in to do chats");
    }
  }, [state]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image removal
  const removeImage = () => {
    setPreviewImage(null);
  };

  if (state?.login == false) {
    return (
      <div
        style={{ height: "65vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <p className="text-center text-light">Sign In to do chats</p>
      </div>
    );
  }

  return loading ? (
    <div className=" d-flex align-items-center justify-content-center loader">
      <Loading stroke={4} />
    </div>
  ) : (
    <>
      <ImageModal
        showImageModal={showImageModal}
        setImageModal={setImageModal}
        degree={degree}
      />
      <div className="container-fluid px-xl-5 px-sm-4">
        <div className="row mx-0">
          <div className="col-xl-9">
            <div className="row mb-3 p-xl-4 px-md-3 py-md-4 px-2 py-3 background-container chat-background">
              <div className="col-lg-4 col-md-5 chat-users-sidebar">
                <div className="mb-3 d-flex align-items-center position-relative">
                  <span className="fa fa-search position-absolute search-icon"></span>
                  <input
                    type="text"
                    placeholder="Search ..."
                    className="form-control user-search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                <div
                  className={`users-list ${
                    filteredUsers.length <= 7
                      ? "overflow-visible"
                      : "overflow-y-auto"
                  } `}
                >
                  {filteredUsers.length > 0 ? (
                    filteredUsers
                      .sort((a, b) => {
                        const dateA = Date.now(a.lastMessage?.createdAt || 0);
                        const dateB = Date.now(b.lastMessage?.createdAt || 0);
                        return dateB - dateA; // Sort in descending order, latest chats first
                      })
                      .map((user) => (
                        <ChatUser
                          clearChat={clearChat}
                          data={blockData}
                          setData={setBlockData}
                          setBlockName={setBlockName}
                          key={user.id}
                          id={user.id} // Pass the user's id
                          name={user.name}
                          lastMessage={user.lastMessage?.text}
                          time={user.lastMessage?.createdAt || "N/A"}
                          image={user?.image}
                          message={message}
                        />
                      ))
                  ) : (
                    <p className="text-light text-small text-center">
                      No user exists
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-8 col-md-7 position-relative">
                {!id ? (
                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                    <span className="fa-solid fa-comments text-light h1"></span>
                    <h3 className="text-light">Chats</h3>
                  </div>
                ) : (
                  <div className="position-absolute w-100 bottom-0 chat-width">
                    <div
                      className={`${
                        messages.length < 3 ? "justify-content-end" : ""
                      } chat-container `}
                      ref={chatContainerRef}
                    >
                      {messages
                        ?.sort(
                          (a, b) =>
                            new Date(a.createdAt) - new Date(b.createdAt)
                        ) // Sort in ascending order
                        .map((msg, index) => (
                          <div
                            key={index}
                            className={
                              msg.sendBy === state.user.id
                                ? "message self"
                                : "message other"
                            }
                          >
                            {msg.image ? (
                              <img
                                onClick={() => {
                                  setImageModal(true);
                                  setDegree(msg.image);
                                }}
                                src={msg.image}
                                alt="image"
                                style={{
                                  width: "200px",
                                  maxHeight: "200px",
                                  objectFit: "cover",
                                }}
                                className="pointer"
                              />
                            ) : (
                              <p className="message-content">{msg.text}</p>
                            )}

                            <span className="mt-2 date">
                              {formatDate(msg.createdAt)}
                            </span>
                          </div>
                        ))}
                      {blockData.find((e) => e == id) && (
                        <div className="text-center">
                          <span
                            style={{ fontSize: "0.7rem" }}
                            className="text-white px-2 py-1 blocked-info rounded-pill"
                          >
                            You blocked {blockName}
                          </span>
                        </div>
                      )}
                      {blockedBy.find((e) => e == state.user?.id) && (
                        <div className="text-center">
                          <span
                            style={{ fontSize: "0.7rem" }}
                            className="text-white px-2 py-1 blocked-info rounded-pill"
                          >
                            You have been blocked
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="type-message position-relative">
                      {previewImage && (
                        <div className="bg-light position-absolute w-100 image-preview-div">
                          <div className="w-100 position-relative">
                            <span
                              className="fa-solid fa-xmark text-danger position-absolute cross-btn pointer"
                              onClick={removeImage}
                            ></span>
                          </div>
                          <div className="w-100 py-3 d-flex justify-content-center">
                            <img
                              style={{
                                height: "100px",
                                maxWidth: "300px",
                                objectFit: "contain",
                              }}
                              src={previewImage}
                              alt="Preview"
                            />
                          </div>
                        </div>
                      )}
                      <textarea
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-100"
                        placeholder="Write a message"
                      />
                      <div className="mt-2 text-end ">
                        <input
                          type="file"
                          id="file"
                          className="d-none"
                          accept="image/*" // Restrict to image files
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="file"
                          className="border-none me-3 bg-transparent p-0 m-0 h5"
                        >
                          <span class="fa-solid fa-paperclip pointer"></span>
                        </label>
                        <button
                          disabled={
                            blockData.find((e) => e == id) ||
                            blockedBy.find((e) => e == state.user?.id)
                          }
                          onClick={onSend}
                          className="btn clr-btn-theme"
                          style={{
                            borderRadius: "50px",
                            padding: "8px 36px",
                            fontSize: "0.875rem",
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 px-xl-2 px-0">
            <Passions />
            <Download />
          </div>
        </div>
      </div>
    </>
  );
};

const ChatUser = ({
  id,
  name,
  setBlockName,
  lastMessage,
  time,
  image,
  clearChat,
  data,
  setData,
}) => {
  const navigate = useNavigate();
  const { id: paramId } = useParams();
  const profile = useRef(null);
  const [select, setSelect] = useState(false);
  const [options, setOptions] = useState(null);
  const optionRef = useRef(null);

  const state = useSelector((state) => {
    return state.user;
  });

  const blockUser = async () => {
    const formData = {
      block_user_id: id,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}api/block/user/${state.user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        setData(res?.data?.blockIds);
        if (res?.data?.message == "user has been unblocked successfully.") {
          toast.success(`${name} has been unblocked`);
        } else {
          toast.success(`${name} has been blocked`);
        }
      })
      .catch((err) => {
        toast.error("There is something went wrong!");
      });
  };

  useEffect(() => {
    setBlockName(name);
  }, [id]);

  useEffect(() => {
    if (paramId == id) {
      setSelect(true);
    } else {
      setSelect(false);
    }
  }, [paramId, id]);

  // Format date function
  const formatDate = (timestamp) => {
    try {
      const date = new Date(timestamp);
      // if (isNaN(date.getTime())) {
      //   throw new Error("Invalid date format");
      // }
      return format(date, "EEE dd - hh:mm a");
    } catch (error) {}
  };

  useEffect(() => {
    if (paramId == id) {
      setSelect(true);
    } else {
      setSelect(false);
    }
  }, [paramId, id]);

  const handleSelect = async () => {
    navigate(`/chat/${id}`);
  };

  const [isHiding, setIsHiding] = useState(false);
  const toggleId = () => {
    if (isHiding) {
      // If we are currently hiding, do not toggle
      setIsHiding(false); // Reset the hiding flag
      return;
    }

    setOptions((prev) => (prev === id ? null : id));
  };

  const handleClickOutside = (event) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      setOptions(null);
      setIsHiding(true); // Set the hiding flag to true
    }
  };

  useEffect(() => {
    if (options !== null) {
      setIsHiding(false); // Reset when opening an option
    }
  }, [options]);
  useEffect(() => {
    if (options !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [options]);

  return (
    <div
      ref={profile}
      className={`d-flex justify-content-between pt-2 px-2 position-relative pointer ${
        select ? "selected-user" : "other-user"
      }`}
    >
      <div className="d-flex">
        <Link to={`/visit-user-profile/${id}`} className="text-decoration-none">
          <div className="me-2 img-holder">
            <img
              src={
                image
                  ? process.env.REACT_APP_API_URL + image
                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              }
              alt="User"
            />
          </div>
        </Link>
        <div onClick={handleSelect}>
          <span
            style={{ textTransform: "capitalize" }}
            className="mb-0 text-nowrap username"
          >
            {name}
          </span>
          <p className="mb-0 user-msg">
            {lastMessage
              ? lastMessage?.length > 8
                ? `${lastMessage.slice(0, 8)}...`
                : lastMessage
              : "Photo"}
          </p>
        </div>
      </div>
      <div onClick={handleSelect} className="d-flex align-items-end">
        <span className="time text-nowrap">{formatDate(time)}</span>
      </div>
      <div
        onClick={toggleId}
        style={{ right: "10px" }}
        className="position-absolute px-1"
      >
        <span className="fa-solid fa-ellipsis-vertical"></span>
      </div>
      {options == id && (
        <div
          ref={optionRef}
          style={{ right: "20px" }}
          className="position-absolute"
        >
          <div
            style={{ zIndex: "99" }}
            className="bg-white shadow position-relative"
          >
            <div
              onClick={() => {
                blockUser();
                setOptions(null);
              }}
              className="py-2 ps-2 pe-4 text-danger border-bottom options-bg-dark"
            >
              <span className="fa-solid fa-ban me-2"></span>
              {data.find((e) => e == id) ? (
                <span>Unblock</span>
              ) : (
                <span>Block</span>
              )}
            </div>
            <div
              onClick={() => {
                setOptions(null);
                clearChat(id);
              }}
              className="py-2 ps-2 pe-4 text-danger border-bottom options-bg-dark"
            >
              <span className="fa-solid fa-trash me-2"></span>
              <span>Clear Chat</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
